<template>
  <b-row>
    <b-col sm="6" v-if="isEnabledForm">
      <h4 class="page-sub-header">Please fill in all fields to preview the widget.</h4>
      <AccessTokenForm
        ref="accessTokenForm"
        v-if="isEnabledAccessTokenForm"
        :data="payload.integrationWidgetSettings"
        @isManualAccessToekn="isManualAccessToekn($event)"
      />
      <PropertyForm ref="propertyForm" v-if="isEnabledPropertyForm" />
      <b-form-row class="mt-3">
        <b-col>
          <b-button type="submit" v-activeBlur variant="secondary" :disabled="previewLoadingIcon" @click="previewWidget"
            >Preview <b-spinner v-if="previewLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
          ></b-button>
          <b-button type="button" v-activeBlur class="ml-1" @click="resetForm" variant="outline-secondary">Reset</b-button>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col class="widget-preview-wrapper" v-if="enableWidgetPreview">
      <LoaderIcon v-if="getPreviewDetailsLoading" />
      <WidgetPreview
        v-else-if="getWidgetPreviewDetailsResponse"
        :widgetDetails="getWidgetPreviewDetailsResponse"
        :expandAllWidgets="false"
        :contactUsUrl="null"
        :goToDashboardWidgetId="null"
        :linkAccountWidgetId="null"
        :isLoading="false"
        :error="null"
        :mode="'Preview'"
      />
      <div v-else>{{ getWidgetPreviewErrorMsg }}</div>
    </b-col>
  </b-row>
</template>
<script>
import LoaderIcon from '../../../../assets/svg/loader.svg'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import { useAxios } from '@/composables/useAxios'
export default {
  name: 'WidgetPreviewWrapper',
  setup() {
    const {
      isLoading: getPreviewDetailsLoading,
      response: getWidgetPreviewDetailsResponse,
      error: getWidgetPreviewDetailsError,
      callApi: getWidgetPreviewDetailsApi,
    } = useAxios()
    return { getPreviewDetailsLoading, getWidgetPreviewDetailsResponse, getWidgetPreviewDetailsError, getWidgetPreviewDetailsApi }
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  provide() {
    return {
      locationURL: ServiceUrls.getWidgetPreviewRecreationLocations,
      categoriesURL: ServiceUrls.getWidgetPreviewRecreationCategories,
      classesURL: ServiceUrls.getWidgetPreviewRecreationClasses,
    }
  },

  data() {
    return {
      previewLoadingIcon: false,
      isValidPropertyForm: false,
      isValidAccessTokenForm: false,
      isManualForm: true,
    }
  },

  components: {
    LoaderIcon,
    WidgetPreview: () => import('@portal/widgets/Widget.vue'),
    PropertyForm: () => import('@/components/common/property-form/PropertyForm.vue'),
    AccessTokenForm: () => import('@/components/common/access-token-form/AccessTokenForm.vue'),
  },
  computed: {
    getWidgetPreviewErrorMsg() {
      return DISPLAY_MESSAGES.WIDGET_PREVIEW_DETAILS_ERROR
    },
    isEnabledAccessTokenForm() {
      return this.payload.widgetCategoryID === 5
    },
    isEnabledPropertyForm() {
      return (
        this.payload.widgetCategoryID === 4 ||
        this.payload.widgetCategoryID === 8 ||
        (this.payload.widgetCategoryID === 5 && this.payload.integrationWidgetSettings.isPropertyDependent && this.isManualForm)
      )
    },
    isEnabledForm() {
      return this.type === '' && (this.payload.widgetCategoryID === 4 || this.payload.widgetCategoryID === 5 || this.payload.widgetCategoryID === 8)
    },
    enableWidgetPreview() {
      return this.isEnabledForm ? this.isValidPropertyForm && this.isValidAccessTokenForm : true
    },
  },
  mounted() {
    if (!this.isEnabledForm) {
      this.getWidgetPreviewDetails()
    }
  },
  methods: {
    isManualAccessToekn(value) {
      this.isManualForm = value
      this.isValidPropertyForm = false
      this.isValidAccessTokenForm = false
    },
    async getWidgetPreviewDetails() {
      this.previewLoadingIcon = true
      await this.getWidgetPreviewDetailsApi({ method: 'post', url: ServiceUrls.getWidgetPreviewDetails, data: this.payload })
      this.previewLoadingIcon = false
    },
    previewWidget() {
      this.isValidPropertyForm = this.$refs.propertyForm ? this.$refs.propertyForm.validateForm() : true
      this.isValidAccessTokenForm = this.$refs.accessTokenForm ? this.$refs.accessTokenForm.validateForm() : true
      if (this.isValidPropertyForm && this.isValidAccessTokenForm) {
        this.payload.prevwAddressInput = this.isValidPropertyForm === true ? null : this.isValidPropertyForm
        this.payload.prevwIntegWdgtInput = this.isValidAccessTokenForm === true ? null : this.isValidAccessTokenForm
        this.getWidgetPreviewDetails()
      }
    },
    resetForm() {
      if (this.$refs.propertyForm) {
        this.$refs.propertyForm.resetForm()
      }
      if (this.$refs.accessTokenForm) {
        this.$refs.accessTokenForm.resetForm()
      }
      this.isValidPropertyForm = false
      this.isValidAccessTokenForm = false
    },
  },
}
</script>
